.mapViewContainer {
  width: 100%;
  height: 500px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapView {
  width: 100%;
  height: 100%;
}
