@import '../../../../styles/Variables';

.heading {
  margin: 0;
  padding: 60px 20px 20px 20px;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  justify-content: space-between;

  .lockIcon {
    padding: 0 4px;
  }

  .toolTip {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;

    span {
      line-height: 20px;
    }
  }
}

.tab {
  height: calc(100% - 106px);

  div:global(.tab-content) {
    height: calc(100% - 76px);
  }

  div:global(.tab-list-item) {
    width: -webkit-fill-available;
    justify-content: center;
  }
}

.tabContainer {
  height: 100%;
  overflow: auto;
}

.scrollableContainer {
  padding: 20px;
}
