@import '../../../styles/Variables';

.actionBarLabel {
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
}

.actionBar {
  background-color: $geodocs--transmittal-actionbar--filter--background-color;
  justify-content: start;
}
.searchBox {
  margin-left: auto;
  input {
    width: 540px;
  }
}

.dropdown {
  width: 200px !important;
}

.marginBetweenFiltersAndSort {
  margin-left: 40px;
}

.marginToSearchButton {
  margin-left: 10px;
}

.marginBetweenDropDowns {
  margin-left: 16px;
}

.filter {
  color: $geodocs--transmittal-filter--color !important;
  border-color: $geodocs--transmittal-filter--border-color !important;
  background-color: $geodocs--transmittal-filter--background-color !important;
}

.exportBtn {
  border: 1px solid;
  border-color: $geodocs--transmittal--action-bar--export-btn--border-color;
  background-color: $geodocs--transmittal--action-bar--export-btn--background-color;
  box-sizing: border-box;
  color: $geodocs--transmittal--action-bar--export-btn--color;
  display: flex;
  align-items: center;
  margin-left: 16px;
  position: relative;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;

  &:hover {
    border-color: $geodocs--transmittal--action-bar--export-btn--hover-border-color;
    background-color: $geodocs--transmittal--action-bar--export-btn--hover-background-color;
    color: $geodocs--black;
  }
}

.exportBtnDisabled {
  cursor: not-allowed;
  color: $geodocs--transmittal--action-bar--export-btn--disabled-color;
  background-color: $geodocs--transmittal--action-bar--export-btn--disabled-background-color;
  border-color: $geodocs--transmittal--action-bar--export-btn--disabled-border-color;
  text-decoration: none;

  &:hover {
    cursor: not-allowed;
    color: $geodocs--transmittal--action-bar--export-btn--disabled-color;
    background-color: $geodocs--transmittal--action-bar--export-btn--disabled-background-color;
    border-color: $geodocs--transmittal--action-bar--export-btn--disabled-border-color;
    text-decoration: none;
  }
}

.totalSelected {
  margin-left: 16px;
}

.sortIconBtn {
  &:focus {
    background-color: $geodocs--transmittal--action-bar--sort-btn--background-color !important;
  }

  &:hover {
    color: $geodocs--black;
  }
}

.expandBtnIcon {
  margin-left: 8px;
  font-size: 21px;
  height: 100%;
  display: contents;
}

.btnMenu {
  display: flex;
  flex-direction: row !important;
  height: 40px;
  align-items: center;
  padding-left: 20px;
  padding-right: 6px;
  z-index: 20;
}

.btnLink {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  font-size: 14px;
  color: $geodocs--button-secondary--color;
  background-color: $geodocs--button-secondary--background-color;
  text-decoration: none;
  .linkContent {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    color: $geodocs--black;
    background-color: $geodocs--ascent;
  }
}

.expandBtn {
  border: 1px solid;
  border-color: $geodocs--transmittal--action-bar--export-btn--border-color;
  background-color: $geodocs--transmittal--action-bar--export-btn--background-color;
  box-sizing: border-box;
  color: $geodocs--transmittal--action-bar--export-btn--color;
  display: flex;
  align-items: center;
  margin-left: 16px;
  position: relative;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.itemsContainer {
  position: absolute;
  top: 17px;
  left: 0;
}

.btnMenuUploadBtn {
  margin-left: 0;
}

.exportFileBtn {
  margin-left: 16px;
}

div:global(.dropdown-options) {
  div:global(.dropdown-item .dropdown-item-icon) {
    margin-right: 0 !important;
  }
  div:global(.dropdown-item > span) {
    padding: 0 12px 0 0px !important;
  }
}
