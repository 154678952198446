@import '../../../Variables';

.appointingPartyContainer {
  padding: 0 24px;
  height: 100%;
  overflow: auto;

  .heading {
    color: $geodocs--AppintingParty-management-heading--color;
    padding: 0 0 24px;
    font-size: 24px;
    width: 100%;
  }

  .row {
    width: 100%;

    &.label {
      font-weight: bold;
      padding: 0 0 16px;
    }
  }

  .header {
    padding: 0 0 24px;

    .appointingPartyInfo {
      padding: 10px 0;
    }
  }

  .addUserBox {
    padding: 24px 0 48px;
    border-bottom: 1px solid $geodocs--AppintingParty-management-bottom-border--color;

    .searchBoxLabel {
      padding: 0 0 8px;
    }

    .addUserContainer {
      align-items: baseline;

      &.selectedUserContainer {
        max-height: 86px;
        justify-content: space-between;
      }

      .searchBox {
        width: 100%;
      }

      .roleInput {
        width: 15%;
      }
    }
  }
  .addForm {
    padding: 0px 46px 16px 0px;
    display: flex;
    justify-content: space-between;

    .label {
      font-weight: 700;
      font-size: 14px;
    }

    .requiredSymbol {
      color: $geodocs--user-selector--required--color;
    }
    .userEmailInput,
    .displayNameInput {
      width: 35%;
    }

    .inputError {
      color: $geodocs--user-selector--required--color;
      font-family: Arial, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-top: 8px;
    }

    .roleInput {
      width: 23%;
    }

    .labelBtn {
      visibility: hidden;
    }
  }
  .UserTable {
    .searchUserBox {
      padding: 24px 0;
      float: right;
    }
  }
}
