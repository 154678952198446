@import '../../../Variables';

.fromWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  .gridGroup {
    margin-top: 24px;
  }

  .settingNoticed {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;

    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: $geodocs--ProjectManagement-add-project-title-color;

    .updateActions {
      display: flex;
    }
  }

  .webSceneWrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;

    button {
      margin-left: 20px;
    }
  }

  .notConfiguredPanel {
    display: flex;
    width: 100%;
    height: 300px;
    background-color: $geodocs-visualisation--error-panel--background-color;
    color: $geodocs-visualisation--error-panel--color;

    p {
      margin: auto;
      font-size: 36px;
    }
  }
}
