@import '../../../styles/Variables';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $geodocs--treeview--background-color;
  box-sizing: border-box;
  margin-left: auto;
  padding-top: 16px;
  height: 100%;
  overflow: auto;

  .expandIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $geodocs--treeview--expand-icon-color;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      background: none;
      color: $geodocs--treeview--expand-icon-color;
    }
    > i {
      margin-right: 0;
    }
  }

  .actionWrapper {
    display: flex;
    height: 56px;
    align-items: center;

    &.showLine {
      border-left: solid 2px $geodocs--treeview--expand-icon-color;
    }
  }

  .rootAction {
    height: 56px;
    display: flex;
    align-items: center;
    padding: 10px 24px;

    .buttonLink {
      padding-left: 0;
    }
  }

  .nodeActions {
    display: flex;
    gap: 8px;
    background-color: $geodocs--white;
    padding-left: 24px;

    .buttonLink {
      display: flex;
      align-items: center;
      font-size: 14px;

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      > i {
        color: $geodocs--treeview--expand-icon-color;
        background-color: $geodocs--white;
      }
    }
  }

  .topBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 10px 24px;
    height: 40px;

    .topBarTitle {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
    }

    .topBarIcon {
      display: flex;
      gap: 16px;
      cursor: pointer;
    }
    .topBarIcon > i {
      font-size: 24px;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .topBarIcon > i:hover {
      background-color: $geodocs--treeview--top-bar-icon-hover-color;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
  }
}

.treeView {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
}

.nodeWrapper {
  box-sizing: border-box;
  padding: 10px;
  padding: 0;
  background-color: $geodocs--white;

  &.expand {
    &.showLine > ul {
      border-left: solid 2px $geodocs--treeview--expand-icon-color;
    }
  }
}

.nodeItem {
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

  &.root {
    padding-left: 24px;
    background-color: $geodocs--style-guide--dropdown--list--background-color;
  }

  &:not(.root) {
    background-color: $geodocs--white;
  }

  &:hover {
    background-color: $geodocs--footer--border-top;
  }

  &.disabled {
    background-color: none;
    span.checkBoxLabel {
      opacity: 0.4;
    }
  }

  &.selected {
    background-color: $geodocs--button--background-color;
    span.nodeTitle {
      color: $geodocs--white;
    }

    .expandIcon {
      color: $geodocs--white;
    }
  }

  .nodeTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: $geodocs--treeview--nodeTitle-color;
    word-wrap: break-word;
    word-break: break-all;

    .treeSearchValue {
      background-color: $geodocs--ascent-light;
    }
    .treeSearchSelectedValue {
      background-color: $geodocs--ascent !important;
    }
  }

  .wrapCheckbox {
    display: flex;
    overflow: auto;

    .checkBox {
      min-width: fit-content;

      div:global(.checkbox.is-indeterminate) {
        background-color: $geodocs--checkbox-is-checked--background-color;
      }
    }

    .checkBoxLabel {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 320px;

      span:global(.tooltip-popup) {
        width: 245px;
        white-space: break-spaces;
      }
    }
  }
}

.actionBarSelected {
  background-color: $geodocs--action-bar--selected--background-color;
}
.searchCount {
  color: $geodocs--treeview--search-filter-count--color;
}
.buttonNavigate {
  margin-left: 0 !important;
  width: 30px !important;
  height: 30px !important;
  background-color: $geodocs--treeview--search-filter--icon--background-color;
  color: $geodocs--treeview--search-filter--icon--color;
  border: 1px solid $geodocs--black;
  &:focus:enabled {
    background-color: $geodocs--treeview--search-filter--icon--background-color;
    border: 1px solid $geodocs--black;
  }
}
