@import '../../../Variables';

.projectDetailsTabWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 0 24px;
  margin-bottom: 24px;

  div:global(.tabs-wrapper .tab-list-active) {
    background-color: $geodocs--button--background-color;
    > span {
      color: $geodocs--white;
    }
  }
  div:global(.tabs-wrapper .tab-list-active::after) {
    background-color: $geodocs--button--background-color;
  }
  div:global(.tabs-wrapper.tabs-medium .tab-list-item) {
    width: 33.333%;
    border: 1px solid $geodocs--button--background-color;
    text-align: center;
    > span {
      width: 100%;
    }
  }

  .projectDetailsSettingTab {
    display: flex;
    flex-direction: column;
    height: auto !important;
  }

  .customTab {
    width: 32%;
  }
}
