@import '../../../Variables';

.projectVisualisationWrapper {
  overflow: hidden;

  .gridGroup {
    margin-top: 24px;
  }

  .settingNoticed {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .breakLine {
    margin-top: 24px;
    border-bottom: solid 1px $geodocs--ProjectManagement-add-project-breakLine-color;
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 24px 0;

    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: $geodocs--ProjectManagement-add-project-title-color;

    &.settingGroup {
      margin-bottom: 16px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 8px;

    .actionButton {
      width: 24px;
      height: 24px;

      &:active:enabled,
      &:focus:enabled {
        color: $geodocs--black;
        background-color: $geodocs--ascent;
      }
    }
  }

  .formDisplayField {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;

    .value {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .formControlLabel {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 14px;
  }

  .notConfiguredPanel {
    display: flex;
    width: 100%;
    height: 500px;
    background-color: $geodocs-visualisation--error-panel--background-color;
    color: $geodocs-visualisation--error-panel--color;
    margin-bottom: 24px;

    p {
      margin: auto;
      font-size: 36px;
    }
  }
}
