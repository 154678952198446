@import '../../../../../styles/Variables';

.container {
  margin: 0 auto;
  width: 760px;
}

%uploadForm {
  display: flex;
  flex-direction: column;
  padding: 23px 25px;
  background: $geodocs--system-file-templates-table-row--background-color;
  max-width: 712px;
  p {
    font-size: 14px;
  }
}

.systemUserImportTable {
  overflow: auto;

  table {
    width: 100%;

    .dropdownMenuBtn {
      background-color: $geodocs--system-users-table--deactive-user-btn-color;
      color: $geodocs--system-users-table--deactive-user-icon-color;
      border: none;
    }
  }
  thead {
    :global {
      tr.table-row.is-header {
        > td.table-cell {
          white-space: nowrap;
        }
      }
    }
  }
}

.tableHeadColumn {
  width: fit-content;
}
.titleNoPadding {
  margin-left: -20px;
  p {
    font-size: 16px !important;
  }
}
.loaderPadding {
  padding: 30px;
}
.downloadTemplate {
  margin-top: 20px;
  margin-bottom: 20px;
}

.linkLabel {
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: $geodocs--system-file-templates--high-light--color;
  text-decoration: underline;
}
.uploadForm {
  @extend %uploadForm;
}

.uploadButtons {
  @extend %uploadForm;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 65px;
}

.errorSummary {
  color: $geodocs--system-file-templates--upload-form--error-summaries-color;
}

.header {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}
.noteAddIcon {
  font-size: 30px;
  padding-top: 30px;
  color: $geodocs--system-file-templates-noted-icon-color;
}
.processCircle {
  color: $geodocs--system-file-templates--high-light--color;
}
.highLight {
  color: $geodocs--system-file-templates--high-light--color;
  font-weight: 700;
}

.importResultTabWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 0 24px;
  margin-bottom: 24px;
  scrollbar-width: thin;
  max-height: 600px;

  div:global(.tabs-wrapper .tab-list-active) {
    background-color: $geodocs--button--background-color;
    > span {
      color: $geodocs--white;
    }
  }
  div:global(.tabs-wrapper .tab-list-active::after) {
    background-color: $geodocs--button--background-color;
  }
  div:global(.tabs-wrapper.tabs-medium .tab-list-item) {
    width: 33.333%;
    border: 1px solid $geodocs--button--background-color;
    text-align: center;
    > span {
      width: 100%;
    }
  }

  div:global(.tabs-wrapper.tabs-medium .tab-list-disabled) {
    border: 1px solid $geodocs--SystemUserImport--tab-disabled-color;
    span {
      color: $geodocs--button--disabled--color;
    }
  }

  .importResultTab {
    display: flex;
    flex-direction: column;
    height: auto !important;
  }
  .customTab {
    width: 32%;
  }

  .actionHeader {
    display: flex;
  }
}
.unSaveModal {
  height: auto;
}
.selectTabLabel {
  font-weight: 400;
  padding: 20px;
  margin-left: -20px;
  p {
    font-size: 16px !important;
  }
}
.headerWeight {
  font-weight: 500 !important;
  font-size: 32px !important;
  line-height: 48px !important;
  font-family: 'Fira Sans', sans-serif;
}
.messageText {
  max-width: 450px;
}
.buttonColor {
  background-color: $geodocs--button--background-color;
  color: $geodocs--white;
}
