@import '../../../Variables';

.projectDetailsWrapper {
  overflow: hidden;

  .gridGroup {
    margin-top: 24px;
  }

  .settingNoticed {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .breakLine {
    margin-top: 24px;
    border-bottom: solid 1px $geodocs--ProjectManagement-add-project-breakLine-color;
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 24px 0;

    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: $geodocs--ProjectManagement-add-project-title-color;

    &.settingGroup {
      margin-bottom: 16px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 8px;

    .tooltipText {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      max-width: 200px;
    }

    .actionButton {
      width: 24px;
      height: 24px;

      &.nonePointerEvents {
        pointer-events: none;
      }

      &:active:enabled,
      &:focus:enabled {
        color: $geodocs--black;
        background-color: $geodocs--ascent;
      }
    }
  }

  .checkbox {
    :global {
      div.checkbox {
        display: flex;
        align-items: center;
      }
    }
  }

  .formDisplayField {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;

    .value {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .formControlLabel {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 14px;
  }

  .projectStatusDot {
    width: 14px;
    height: 14px;
  }
  .projectStatusText {
    font-size: 14px;
    line-height: 16px;
  }

  .inProgress {
    background-color: $geodocs--task--assigned;
  }
  .failed {
    background-color: $geodocs--error;
  }
  .active {
    background-color: $geodocs--task--approved;
  }
  .inactive {
    background-color: $geodocs--transmittals-item--closed--background-color;
  }

  .projectStatusWrapper {
    .statusFailedMessage,
    .statusFailedMessage > a {
      color: $geodocs--error;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .formGroupLabel {
    margin: 12px 0 12px 0;
    font-weight: bold;
    font-size: 16px;
    color: $geodocs--ProjectManagement-add-project-title-color;
  }

  .formWorkflowLabel {
    margin: 12px 0 12px 0;
    font-weight: bold;
    font-size: 14px;
  }
  .formGroupContent {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .toggle {
    display: flex;
    flex-wrap: nowrap;
    padding: 12px 0 12px 0;

    label {
      font-size: 14px;
      font-weight: normal;
      width: 227px;
    }
  }
}
