@import '../../../styles/Variables';

.navBarSelector {
  .selectedItem {
    button {
      height: unset;
      padding: 0;
      margin-left: 0;
    }
  }

  .searchSuggestions {
    ul:global(.suggestions) {
      max-height: 90vh;
      background-color: $geodocs--style-guide--dropdown--list--background-color !important;
      border: 1px solid $geodocs--ascent-dark;

      & li:hover {
        background-color: $geodocs--style-guide--search--background-color !important;
        border-color: $geodocs--style-guide--dropdown--hover--border-color;
        color: $geodocs--style-guide--dropdown--hover--color;
      }

      li:has(div.active) {
        background-color: $geodocs--style-guide--search--background-color;
        color: $geodocs--style-guide--dropdown--color;
        border: 1px dashed $geodocs--ascent-dark;
      }
    }

    div:global(.field-wrapper) {
      > label {
        > div {
          > input {
            background-color: $geodocs--style-guide--search--background-color !important;
            color: $geodocs--style-guide--dropdown--color !important;
            border: 1px solid $geodocs--ascent-dark;
            border-color: $geodocs--ascent-dark;
          }
          > input:focus {
            border-color: $geodocs--ascent-dark;
          }

          i {
            position: absolute;
            align-self: center;
            color: $geodocs--style-guide--dropdown--color;
            cursor: pointer;
          }
        }
      }
    }

    i:global(.searchbox-search-icon) {
      left: 12px;
      font-size: 20px;
    }
    i:global(.searchbox-expand-icon) {
      right: 12px;
      font-size: 22px;
    }
    i:global(.searchbox-clear-icon) {
      right: 40px;
      font-size: 18px;
    }
  }

  .defaultHeight {
    ul:global(.suggestions) {
      max-height: 202px;
    }
  }

  div:global(.dropdown-selected) {
    > span {
      margin-left: 0;
    }
    div {
      margin-left: 0;
      > span > div {
        margin-left: 0;
      }
    }
  }

  div:global(.dropdown-selected):hover {
    background-color: $geodocs--style-guide--dropdown--hover--background-color !important;
    border-color: $geodocs--style-guide--dropdown--hover--background-color !important;
  }
}
