@import '../../../Variables';

.pageWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden !important;

  .cellInputRight {
    padding: 0 0 0 8px;
  }

  .cellInputLeft {
    padding: 0 8px 0 0;
  }
}

.teamManagementTree {
  width: 400px;
}

.left {
  width: 400px;
  border-right: 1px solid $geodocs--teamManagement-border-left-color;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px 0 0 24px;

  .noticed {
    border: 1px solid $geodocs--teamManagement-noticed-border-color;
    padding: 16px;
    height: max-content;
    color: $geodocs--teamManagement-noticed-text-color;
    border-left: solid 4px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      color: $geodocs--style-guide--tab--color;
      line-height: 21px;

      .closedBtn {
        font-size: 20px;
        width: 20px;
        line-height: 21px;

        &:hover {
          background-color: $geodocs--teamManagement-noticed-close-icon-hover-color;
        }
      }
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
    }
  }
  .distributionButton {
    margin-top: 24px;
  }
  .comingSoon {
    border: 1px solid $geodocs--teamManagement-noticed-border-color;
    padding: 16px;
    height: max-content;
    color: $geodocs--teamManagement-noticed-text-color;
    border-left: solid 4px;
  }
}
.charNum {
  display: initial !important;
  font-size: 14px;
  float: right;
  color: $geodocs--button--disabled--color;
}

.createNewTeamModal {
  .modalActions {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -50%;
      height: 1px;
      width: 155%;
      background-color: rgba(0, 0, 0, 0.25);
      overflow-x: hidden;
    }
    margin-top: 24px;
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;

    button {
      margin-left: 12px;
    }
  }
}
