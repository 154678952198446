@import '../../../styles/Variables';

.list {
  min-width: 160px;
  :global {
    div.combobox-content {
      .field-wrapper > label .input-field {
        min-width: 180px !important;
        display: flex;
        position: initial !important;
      }
      .field-wrapper > label .input-field.is-open,
      .field-wrapper > label .input-field:hover {
        background-color: $geodocs--ascent;
      }

      .field-wrapper > label .input-field.is-dropdown {
        .dropdown-icon {
          right: 0px;
        }
        .clear-icon {
          right: 20px;
        }
      }
      .field-wrapper > label .input-field.is-dropdown input {
        font-size: 11px;
        height: 24px;
      }
      .options-wrapper .options .ellipsis {
        height: 24px !important;
        font-size: 11px !important;
        line-height: 24px !important;
        overflow: unset;
      }
    }

    .input-field.is-dropdown .multiple-choice-count {
      right: 50px !important;
      top: 12px !important;
    }

    .input-field.is-dropdown.multiple.multiple-selected .multiple-choice-count {
      right: 4px;
      top: 12px;
    }

    .input-field.is-dropdown.multiple input {
      padding-right: 65px !important;
    }
  }
}
