.modal {
  width: 500px;
  max-height: 250px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left;
  margin-top: 10px;
}
