@import '../../../styles/Variables';

.exportByFileModal {
  overflow: unset;

  .title {
    font-size: 24px;
    line-height: 36px;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
  }

  .label {
    font-size: 14px;
    line-height: 40px;
    font-weight: bold;
  }

  .inputError {
    color: $geodocs--error;
    font-weight: normal;
    font-size: 12px;
    line-height: 28px;
  }
  .modalActions::before {
    left: -24px;
    width: 110%;
  }
  .wrapperDateFilter {
    border-bottom: 0;
  }
  .datePicker {
    :global {
      div.date-component {
        .react-daterange-picker,
        .react-date-picker,
        .react-date-picker__wrapper > button {
          height: 40px !important;
          border-bottom: 0;
          background-color: $geodocs--style-guide--dropdown--background-color;
          margin-left: 10px;
        }
        .react-date-picker__wrapper {
          background-color: $geodocs--style-guide--dropdown--background-color;
        }
      }
    }
  }
}
