@import '../../../styles/Variables';

.actionBar {
  border-bottom-style: solid;
  border-top-style: solid;
}

.expandBtnIcon {
  margin-left: 8px;
  font-size: 21px;
  height: 100%;
  display: contents;
}

.btnMenu {
  display: flex;
  flex-direction: row !important;
  height: 40px;
  align-items: center;
  padding-left: 12px;
  padding-right: 6px;
}

.expandBtn {
  display: flex;
  color: $geodocs--transmittals-create-transmittal-actions--dropdown-menu--color;
  background-color: $geodocs--transmittals-create-transmittal-actions--dropdown-menu--background-color;
  border: 1px solid $geodocs--transmittals-create-transmittal-actions--dropdown-menu--border-color;
  margin: 0 16px;
  line-height: 1.5;
  height: 40px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 11;
}

.itemsContainer {
  position: absolute;
  top: 17px;
  left: 0;
}

.btnMenuUploadBtn {
  margin-left: 0;
}
