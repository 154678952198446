@import '../../../../styles/Variables';

.fileTable {
  height: calc(100% - 130px);
  overflow: auto;

  thead {
    :global {
      tr.table-row.is-header {
        > td.table-cell {
          white-space: nowrap;

          .table-sort-icon {
            margin: 5px 0;
          }
        }
      }
    }

    .tableListFilter {
      min-width: unset;
      :global {
        .combobox-content {
          .field-wrapper > label {
            .input-field {
              min-width: unset !important;
            }
          }
        }
      }
    }
  }

  table {
    width: 100%;
    & thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
    td:first-child,
    td:nth-child(1) {
      left: 0;
      background-color: $geodocs--file-table--primary-columns--background-color !important;
    }
    td:last-child {
      position: sticky;
      right: 0;
      background-color: $geodocs--file-table--action-column--background-color !important;

      &:hover {
        z-index: $geodocs--file-table--action-column--hover--z-index;
      }
    }
  }
}

.fileTableLoading {
  height: calc(100% - 130px);
  overflow: auto;
  background: $geodocs--file-table--loading--background-color;
  table {
    table-layout: fixed;
  }
  thead {
    :global {
      tr.table-row.is-header {
        > td.table-cell {
          white-space: nowrap;
        }
      }
    }
    .tableListFilter {
      min-width: unset;
      :global {
        .combobox-content {
          .field-wrapper > label {
            .input-field {
              min-width: unset !important;
            }
          }
        }
      }
    }
  }
}
.tableHeadColumn {
  width: fit-content;
}

.paginationWrapper {
  position: absolute;
  bottom: 0;
  height: 130px;
  width: 100%;
}

.paginationContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 52px;
  padding-top: 30px;

  .disabled {
    cursor: not-allowed;

    button {
      pointer-events: none;
    }
  }
  .paginationFlexGrow {
    position: absolute;
    right: 48px;
    padding-top: 0.9375rem;
  }
}

.fileLoadingSpinner {
  position: fixed;
  top: 50%;
  right: calc(50% - 136px);
  z-index: 100;
}
