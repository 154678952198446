@import '../../Variables';

.errorPanel {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: $geodocs-visualisation--error-panel--background-color;
  color: $geodocs-visualisation--error-panel--color;

  p {
    margin: auto;
    font-size: 36px;
  }
}
