@import '../../../styles/Variables';

.label {
  display: flex;
  column-gap: 12px;
  font-weight: 400;
  color: $geodocs--selected-files-label--color;
  margin-left: 16px;
  align-items: center;
}
.warning {
  color: $geodocs--selected-files-warning-label--color;
}
.linkLabel {
  cursor: pointer;
  font-size: 14px;
  color: $geodocs--selected-files-link-label--color;
  background-color: transparent;
  border: none;
  height: fit-content;
  padding: 0;
  text-decoration: underline;

  &:hover {
    background-color: transparent !important;
  }
}
