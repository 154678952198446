@import '../../../styles/Variables';

.actionBarLabel {
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
}

.marginBetweenFiltersAndSort {
  margin-left: 40px;
}

.marginToSearchButton {
  margin-left: 10px;
}

.actionBar {
  .dropdown {
    width: 200px !important;

    div:global(.is-dropdown) {
      input {
        display: inline-block;
        text-overflow: ellipsis;
        padding-right: 60px;
      }
    }
  }

  button.exportButton {
    margin: 0 16px;
    background-color: $geodocs--white;

    &:active,
    &:focus {
      background-color: $geodocs--white;

      i {
        color: $geodocs--black;
      }
    }

    i {
      color: $geodocs--black;
    }
  }
}
