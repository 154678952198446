@import '../../../Variables';

.modal {
  width: 500px;
  height: auto;
}
.container {
  display: flex;

  .selectorWrapper {
    flex-grow: 1;
  }

  .label {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
    display: block;
  }

  .required {
    position: relative;
    color: $geodocs--user-selector--required--color;
    margin-left: 8px;
    vertical-align: sub;
  }

  .buttonWrapper {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
  }
}

.requiredField {
  font-size: 12px;
  color: $geodocs--user-selector--required--color;
  margin-top: 8px;
}
.containerToggle {
  margin-top: 15px;
  margin-bottom: 25px;
}
