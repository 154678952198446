@import '../../../Variables';

.gridGroupWrapper {
  margin-top: 24px;
  size: 20px;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: $geodocs--ProjectManagement-fileMetadata-header-color;

    &.settingGroup {
      margin-bottom: 16px;
    }
  }
  .actionButtonsWrapper {
    display: flex;
  }
}

.gridTableWrapper {
  .contentWrapper {
    width: 100% !important;
  }

  .addNewButton {
    margin-top: 20px;
    margin-bottom: 20px;
    color: $geodocs--ProjectManagement-fileMetadata-add-new-type-button-color;
  }
}

.fileMetadataAccordion {
  div:global(.accordion-panel) {
    display: flex;
    flex-direction: row-reverse;
    border: none;
    background-color: transparent !important;
    margin-bottom: 0 !important;
    padding: 0;
  }

  div:global(.accordion-content) {
    border-top: $primary--border--width solid rgba($primary--text--colour, 0.25);
    background-color: transparent !important;
    margin-bottom: 0 !important;
    padding: 10px 0 0;
  }
}

@mixin rowWrapper {
  .row {
    padding: 12px 0;
    font-size: 14px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    display: flex;
    align-items: center;
    line-height: 36px;
    word-break: break-word;

    .childRow {
      padding-left: 30px;
    }

    .required {
      color: $geodocs--ProjectManagement-fileMetadata-required--color;
      padding-left: 5px;
    }

    .fieldType {
      font-weight: normal;
    }
  }
}

@mixin textWrapper {
  .textOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  .inputWrapper {
    width: 100%;

    :global {
      .combobox-wrapper {
        .clear-icon {
          display: none;
        }
      }
    }
    .inputError {
      color: $geodocs--error;
      font-weight: normal;
      font-size: 12px;
      line-height: 28px;
    }
  }
  .charactersLeft {
    font-weight: 400;
    font-size: 12px;
    color: $geodocs--ProjectManagement-fileMetadata--disabled--color;
  }
}

.rowWrapperType {
  display: flex;
  width: 100%;
  margin: 10px 0;
  align-items: center;

  &.childWrapper {
    background-color: #fafafa;
    margin: 0 0 10px 0;
  }

  @include rowWrapper();

  @include textWrapper();

  .archiveButton {
    padding-top: 0;
    padding-bottom: 0;
  }
  &.disableRow {
    color: $geodocs--ProjectManagement-transmittalDefaults-breakLine-color;
  }

  .actionWrapper {
    display: flex;
    justify-content: center;
    width: 10%;
  }
}

.tooltipText {
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  width: 100%;
  font-size: 16px;
}

.lastNestedRowWrapper {
  border-top: $primary--border--width solid rgba($primary--text--colour, 0.25) !important;
  border-bottom: 0px !important;
}
