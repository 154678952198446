@import '../../../../../styles/Variables';

.modalOverlay {
  background-color: $geodocs--upload-progress--overlay--background-color;
  opacity: 0.9;
}

.modal {
  min-width: 500px;
  max-width: 30% !important;
  position: relative;
  z-index: $geodocs--upload-progress--modal--z-index;
  background-color: transparent;
  overflow: hidden;
  & h4,
  p {
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
  }
  & p,
  a {
    font-size: 12px;
    line-height: 30px;
    color: $geodocs--black;
  }
}

.filler {
  height: 70%;
  background-color: $geodocs--upload-progress--bar--background-color;
  border-radius: inherit;
}

.container {
  height: 5px;
  width: 100%;
  background-color: $geodocs--upload-progress--container--background-color;
  border-radius: 10px;
  margin: 5px 10px 5px 0px;
}
.exportButton {
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid $geodocs--black;
  color: $geodocs--black;
  text-decoration: none;

  &:hover {
    background: $geodocs--upload-form--export-btn--hover-background-color;
    border: 1px solid $geodocs--upload-form--export-btn--hover-background-color;
  }
}
.message {
  display: flex;
  align-items: center;
  margin: 0 0 24px;

  i {
    margin-right: 24px;
    &.success {
      color: $geodocs--upload-file-ready-status--color;
    }

    &.warning {
      color: $geodocs--icon--warning;
    }
  }

  p {
    margin: 0;
  }
}

.label {
  padding: 5px;
  color: $geodocs--black;
  font-weight: bold;
}
