@import '../../../Variables';

.addUserBox {
  padding: 24px 0 48px;
  border-bottom: 0px solid $geodocs--team-management-bottom-border--color;

  .heading {
    color: $geodocs--team-management-heading--color;
    padding: 0 0 24px;
    font-size: 24px;
    width: 100%;
    display: flex;
    flex-grow: 1;
  }

  .addNewSession {
    background: $geodocs-very-light-gray;
    box-sizing: border-box;
    padding: 20px;
  }

  .addForm {
    padding: 0px 46px 16px 0px;
    display: flex;
    justify-content: space-between;

    .label {
      font-weight: 700;
      font-size: 14px;
      padding: 0 0 16px;
    }

    .requiredSymbol {
      color: $geodocs--user-selector--required--color;
    }
    .userEmailInput,
    .displayNameInput {
      width: 35%;
    }

    .roleInput {
      width: 23%;
    }

    .labelBtn {
      visibility: hidden;
      font-weight: 700;
      font-size: 14px;
    }
    .btnAdd {
      margin-top: 16px;
    }
  }
}
