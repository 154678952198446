@import '../../../styles/Variables';

.modalBody {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .loader {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
  .previewLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
  }

  .pdfContainer {
    height: 100%;
    width: 100%;

    :global {
      .react-pdf__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;

        .react-pdf__Page {
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
          margin: 20px 0;
        }

        .react-pdf__message.react-pdf__message--error {
          display: flex;
          height: 100vh;
          align-items: center;
        }
      }
    }
  }

  button + button {
    margin-left: 0;
  }
}
.noScrollbars {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.noScrollbars::-webkit-scrollbar {
  display: none;
}

.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: none;
  border: $geodocs--transmittals-create-transmittal-actions--dropdown-menu--color;
}
.pdfContainer:hover {
  .button {
    background-color: $geodocs--FileInformation--PreviewOpenFile-hover-color;
    border-color: $geodocs--FileInformation--PreviewOpenFile-hover-color;
    color: $geodocs--white;
    display: block;
  }
}
