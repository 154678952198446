.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  background: none;
  border: none;
  justify-content: flex-start;
  width: 100%;

  &:hover,
  &:focus {
    background: none !important;
    color: inherit !important;
  }

  * + * {
    margin-left: 10px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    font-size: 12px;
  }
}
