@import '../../../styles/Variables';

.tabContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

.transmittalList {
  height: calc(100% - 186px);
  overflow: auto;
}

.noDataWrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  .noDataMessage {
    text-align: center;
  }
}

.pointer {
  padding: 0;

  &:focus,
  &:active {
    border: none !important;
  }

  &:hover {
    background: none !important;
  }

  span {
    text-decoration: underline;
    font-size: initial;
  }
}

.loadingSpinnerWrapper {
  position: absolute;
  top: 156px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: $geodocs--transmittals-tab-container-progress--overlay--background-color;
}

.loadingSpinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
