@import '../../../../styles/Variables';

.filenameCell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .lockIcon {
    font-size: 16px;
  }
}

.wordWrap {
  display: flex;
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
}

.overFlow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkbox {
  padding: 0 !important;
  > button {
    background-color: transparent;
    border: none;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
    }
  }
}
