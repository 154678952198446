@import '../../../styles/Variables';

.users {
  padding: 0;
  width: 100%;
}

.assignUser {
  display: flex;
  flex-direction: column;
  width: calc(100% - 120px);
}
.assignedTo {
  display: flex;
}
.reAssignUser {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
.requiredAllApprovedText {
  color: $geodocs--treeview--expand-icon-color;
  padding-bottom: 15px;
  line-height: 31px;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;

  & + & {
    margin-top: 8px;
  }
}

.namePanel {
  width: calc(100% - 130px);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name {
  margin-left: 16px;
}

@media (min-width: 1500px) {
  .userCircleCollection {
    display: none;
  }
}

@media (max-width: 1500px) {
  .userCircle,
  .requiredAllApprovedText {
    display: none;
  }
}
