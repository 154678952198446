@import '../../../Variables';

.fromWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  overflow-y: auto;

  .gridGroup {
    margin-top: 24px;

    .transmittalSetting {
      margin-bottom: 48px;
      font-size: 20px;
    }
  }

  .settingNoticed {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .breakLine {
    margin-top: 24px;
    border-bottom: solid 1px $geodocs--ProjectManagement-add-project-breakLine-color;
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: $geodocs--ProjectManagement-add-project-title-color;

    &.settingGroup {
      margin-bottom: 16px;
    }

    .addProjectActions {
      display: flex;
    }
  }

  .checkbox {
    :global {
      div.checkbox {
        display: flex;
        align-items: center;
      }
    }
  }

  .formDisplayField {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;

    .value {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .formControlLabel {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 14px;

    .required {
      position: inherit;
      color: $geodocs--user-selector--required--color;
      margin-left: 8px;
      margin-top: 4px;
    }
  }

  .formItemWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    .combobox {
      :global {
        span.combobox-required {
          position: static;
        }
      }
    }
  }

  .formGroupLabel {
    margin: 12px 0 12px 0;
    font-weight: bold;
    font-size: 16px;
    color: $geodocs--ProjectManagement-add-project-title-color;
  }
}
