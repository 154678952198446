@import '../../../../styles/Variables';

.icon {
  font-size: 14px;
}

.projectItem {
  margin-left: 12px;

  color: $geodocs--black;

  &:hover {
    color: inherit !important;
  }
}
