@import '../../../../styles/Variables';

.container {
  cursor: default;
  color: $geodocs--button--disabled--color;

  &:hover {
    color: inherit !important;
  }
}
