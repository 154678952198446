@import '../../../Variables';

.pageWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .heading {
    color: $geodocs--team-management-heading--color;
    padding: 0 0 24px;
    font-size: 24px;
    width: 100%;
    display: flex;
    flex-grow: 1;

    .title {
      width: 80%;
    }
    .buttons {
      width: 20%;
      display: flex;
      justify-content: flex-end;

      .editIcon {
        cursor: pointer;
        position: relative;
        top: 3px;
      }
    }
  }

  .quickSearch {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;

    .exportButton {
      display: flex;
      justify-content: flex-start;
      width: 70%;
    }
  }

  .row {
    width: 100%;

    &.label {
      font-weight: bold;
      padding: 0 0 16px;
    }
  }

  .halfRow {
    width: 50%;

    &.label {
      font-weight: bold;
      padding: 0 0 16px;
    }
  }

  .flex {
    display: flex;
  }

  .addNewSession {
    background: $geodocs-very-light-gray;
    box-sizing: border-box;
    padding: 20px;
  }

  .addUserRow {
    display: flex;
    width: 100%;
    align-items: flex-end;

    .required {
      color: $geodocs--user-selector--required--color;
      margin-left: 8px;
    }
  }

  .labelBtn {
    visibility: hidden;
    width: 50px;
    font-weight: 700;
    font-size: 14px;
  }

  .addUserBox {
    padding: 24px 0 48px;
    border-bottom: 0px solid $geodocs--team-management-bottom-border--color;

    .searchBoxLabel {
      padding: 0 0 8px;
    }

    .addUserContainer {
      align-items: baseline;
      width: 100%;

      &.selectedUserContainer {
        max-height: 86px;

        button {
          align-self: flex-end;
        }
      }

      .searchBox {
        width: 100%;
      }

      .addUserButton {
        margin-left: 16px;
      }
    }
  }
}
