@import '../../../styles/Variables';

.exportBtn {
  border: 1px solid;
  background-color: $geodocs--button--color;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-left: 16px;
  position: relative;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  &:hover {
    background-color: $geodocs--drop-down-menu--icon--hover--background-color;
  }
}
.btnMenu {
  display: flex;
  flex-direction: row !important;
  height: 40px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 20;
}
.itemsContainer {
  position: absolute;
  top: 17px;
  left: 0;
}
