@import '../../../styles/Variables';

.label {
  display: flex;
  column-gap: 12px;
  font-weight: 400;
  color: $geodocs--selected-files-label--color;
  margin-left: 16px;
  font-size: 14px;
  align-items: center;
}

.totalSelected {
  align-items: center;
}

.linkLabel {
  cursor: pointer;
  font-size: 14px;
  color: $geodocs--selected-files-link-label--color;
  padding: 0;

  &:focus,
  &:active {
    border: none !important;
  }
  span {
    text-decoration: underline;
  }
}
