@import '../../../../styles/Variables';

.modal {
  min-width: $geodocs--modal--width;
  max-height: 90%;
  overflow-y: auto;

  .container {
    padding: 0 24px;

    .filesTableWrapper {
      background: $geodocs--table-wrapper--background-color;
      padding: 0 15px 15px 15px;

      div:global(.table-resize-handle) {
        max-height: 100%;
      }

      .labelWrapper {
        display: flex;
        justify-content: space-between;
      }
      .label {
        font-size: 14px;
        padding: 10px 0;
      }

      .fileContainersToSupersedeTable {
        overflow: auto;

        .fileWrapper {
          display: flex;
          align-items: center;

          .fileIcon {
            transform: scaleY(-1);
          }

          span {
            position: relative;
            padding-left: 15px;
            top: 2px;
          }
        }
      }

      .filesTable {
        overflow: auto;
        & thead {
          position: sticky;
          top: 0;
          z-index: 10;
        }

        .previewIcon {
          font-size: 20px;
          background: transparent;
        }

        .rowDisabled {
          color: $geodocs--table-cell--span--disabled--color;
        }
      }
    }
  }
}
